.ant-notification-notice-message {
  line-break: auto !important;
}
.ant-picker .ant-picker-input > input[disabled],
.ant-select-disabled .ant-select-selector,
.ant-select-disabled .ant-select-selection-item,
.ant-input[disabled] {
  color: rgba(0, 0, 0, 0.88) !important;
}
.ant-checkbox-disabled+span{
  color: rgba(0, 0, 0, 0.88) !important;
}